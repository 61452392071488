.rc-table {
    font-size: 12px;
    color: #666;
    transition: opacity 0.3s ease;
    position: relative;
    line-height: 1.5;
    overflow: hidden;
}

.rc-table-body-outer {
    /*note: this prevents the bottom from chopping off.*/
    margin: 0 !important;
}

.rc-table-fixed-left tbody tr:last-child,
.rc-table-fixed-right tbody tr:last-child {
    border-bottom: 19px solid #f7f7f7 !important;
    /*margin-bottom: 19px !important;*/
}

.rc-table-row.group {
    /*font-weight: 900;*/
    background: repeating-linear-gradient(
            -45deg,
            #fafafa,
            #FAFAFA 5px,
            #ffffff 5px,
            #ffffff 10px
    )
}

.rc-table tr.group.rc-table-row-hover,
.rc-table tr.rc-table-row.group:hover {
    background: repeating-linear-gradient(
            -45deg,
            #eaf8fe,
            #eaf8fe 5px,
            #ffffff 5px,
            #ffffff 10px
    )
}

.rc-table-row.child {
    background: #FAFAFA
}

.rc-table-row.single {
    background: #ffffff
}


.rc-table-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.rc-table .rc-table-scroll {
    overflow: auto;
}

.rc-table .rc-table-scroll table {
    width: auto;
    min-width: 100%;
}

.rc-table .rc-table-header {
    overflow: hidden;
    background: #f7f7f7;
    margin: 0 !important;
}

.rc-table-fixed-header .rc-table-body {
    background: #fff;
    position: relative;
}

.rc-table-fixed-header .rc-table-body-inner {
    height: 100%;
    overflow: hidden !important;
}

.rc-table-fixed-header .rc-table-scroll .rc-table-header {
    /*overflow-x: scroll;*/
    /*padding-bottom: 20px;*/
    /*margin-bottom: -20px;*/
    /*overflow-y: scroll;*/
    box-sizing: border-box;
}

.rc-table-fixed-columns-in-body {
    visibility: hidden;
    pointer-events: none;
}

.rc-table .rc-table-title {
    /*padding: 16px 8px;*/
    padding: 0;
    border-top: 1px solid #e9e9e9;
}

.rc-table .rc-table-content {
    position: relative;
}

.rc-table .rc-table-footer {
    /*padding: 16px 8px;*/
    padding: 0;
    border-bottom: 1px solid #e9e9e9;
}

.rc-table .rc-table-placeholder {
    /*padding: 16px 8px;*/
    padding: 0;
    background: #fff;
    border-bottom: 1px solid #e9e9e9;
    text-align: center;
    position: relative;
}

.rc-table .rc-table-placeholder-fixed-columns {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: transparent;
    pointer-events: none;
}

.rc-table table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    border-spacing: 0;
}

.rc-table th {
    background: #f7f7f7;
    border: none;
    border-right: 1px solid #e9e9e9;
    font-weight: bold;
    transition: background .3s ease;
    overflow: hidden;
}

.rc-table td {
    border-top: none;
    border-left: none;
    border-bottom: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
    overflow: hidden;
}

.rc-table td:empty:after {
    content: '.';
    visibility: hidden;
}

.rc-table tr {
    border: none;
    transition: all .3s ease;
}

.rc-table tr:hover {
    background: #eaf8fe;
}

.rc-table tr.rc-table-row-hover {
    background: #eaf8fe;
}

.rc-table th,
.rc-table td {
    padding: 0;
    white-space: nowrap;
}

.rc-table-expand-icon-col {
    width: 34px;
}

.rc-table-row-expand-icon,
.rc-table-expanded-row-expand-icon {
    cursor: pointer;
    display: inline-block;
    width: 16px;
    height: 16px;
    text-align: center;
    line-height: 16px;
    border: 1px solid #e9e9e9;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: #fff;
}

.rc-table-row-spaced,
.rc-table-expanded-row-spaced {
    visibility: hidden;
}

.rc-table-row-spaced:after,
.rc-table-expanded-row-spaced:after {
    content: '.';
}

.rc-table-row-expanded:after,
.rc-table-expanded-row-expanded:after {
    content: '-';
}

.rc-table-row-collapsed:after,
.rc-table-expanded-row-collapsed:after {
    content: '+';
}

tr.rc-table-expanded-row {
    background: #f7f7f7;
}

tr.rc-table-expanded-row:hover {
    background: #f7f7f7;
}

.rc-table-column-hidden {
    display: none;
}

.rc-table-prev-columns-page,
.rc-table-next-columns-page {
    cursor: pointer;
    color: #666;
    z-index: 1;
}

.rc-table-prev-columns-page:hover,
.rc-table-next-columns-page:hover {
    color: #2db7f5;
}

.rc-table-prev-columns-page-disabled,
.rc-table-next-columns-page-disabled {
    cursor: not-allowed;
    color: #999;
}

.rc-table-prev-columns-page-disabled:hover,
.rc-table-next-columns-page-disabled:hover {
    color: #999;
}

.rc-table-prev-columns-page {
    /*margin-right: 8px;*/
}

.rc-table-prev-columns-page:before {
    content: '<';
}

.rc-table-next-columns-page {
    float: right;
}

.rc-table-next-columns-page:before {
    content: '>';
}

.rc-table-fixed-left,
.rc-table-fixed-right {
    position: absolute;
    top: 0;
    overflow: hidden;
}

.rc-table-fixed-left table,
.rc-table-fixed-right table {
    width: auto;
    background: #fff;
}

.rc-table-fixed-left {
    left: 0;
    box-shadow: 4px 0 4px rgba(100, 100, 100, 0.1);
}

.rc-table-fixed-left .rc-table-body-inner {
    padding: 0;
    margin: 0;
    /*margin-right: -20px;*/
    /*padding-right: 20px;*/
}

.rc-table-fixed-header .rc-table-fixed-left .rc-table-body-inner {
    padding-right: 0;
}

.rc-table-fixed-right {
    right: 0;
    box-shadow: -4px 0 4px rgba(100, 100, 100, 0.1);
}

.rc-table-fixed-right .rc-table-expanded-row {
    color: transparent;
    pointer-events: none;
}

.rc-table.rc-table-scroll-position-left .rc-table-fixed-left {
    box-shadow: none;
}

.rc-table.rc-table-scroll-position-right .rc-table-fixed-right {
    box-shadow: none;
}
